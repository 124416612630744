<template>
  <div 
    v-if="noResults"
    class="w-full flex flex-wrap items-center justify-center border base-border-grey rounded-md mt-8"
  >
    <div class="h-40 w-40 rounded-full base-bg-checkbox mt-14"></div>
    <p class="w-full text-xl text-black leading-none text-center my-10"> Loading </p>
  </div>
  <div 
    v-else
    class="w-full h-36 flex flex-wrap flex-col items-center justify-center border base-border-grey rounded-md mt-8"
  >
    <p class="text-xl text-black leading-none mt-4"> {{ text }} </p>
    <br/>
    <Loader 
      v-if="loading"
      :animation="true"
      classes="w-4 h-4 ml-3"
    />
    <router-link v-else to="/bucket-admin">
      <button 
        class="h-7 font-monoDemi border border-black inline-block text-xs px-3 rounded-2xl whitespace-nowrap text-black"
      >
        Create a bucket
      </button>
    </router-link>
  </div>
</template>

<script>
  import Loader from '@/components/Loader'

  export default {
    props: [ 'text', 'noResults', 'buttonText', 'loading' ],
    components: {
      Loader
    },
  }
</script>

<style lang="scss" scoped>

</style>