<template>
  <div class="w-full">
    <ul>
      <li 
        v-for="bucket in items"
        :key="bucket.bucketId"
        class="border-dashed border-b bucket-line"
      >
        <router-link 
          :to="`/buckets/${bucket.bucketId}`" 
          class="flex items-center justify-between py-3 px-3"
        >
          <span class="text-xl base-font-gray-200"> {{ bucket.name }} </span>
          <span class="font-mono text-sm"> {{ bucket.channels }} channels</span>
        </router-link>
      </li>
    </ul>

    <button 
      v-if="isCreate"
      class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm font-mono font-medium mt-4 ml-4"
      @click="createNewBucket"
    > 
      Create a New Bucket 
    </button>
  </div>
</template>

<script>

  export default {
    props: [ 'items', 'isCreate' ],
    methods: {
      createNewBucket(){
        this.$emit('createNewBucket')
      }
    },
  }
</script>

<style lang="scss" scoped>
.bucket-line{
  &:hover{
    background-color: #F9F9F9;
  }
}
</style>