<template>
  <div class="mb-32">
    <div class="head-wrap mx-auto mt-40">
      <p class="font-bold head-title text-black leading-none"> Buckets </p>
      <p class="base-font-gray-200 text-2xl mt-6">
        Buckets contain a curated list of YouTube channels that you can search 
        through or track over time. Community buckets are managed by the community 
        and are freely accessible. Team users can create their own personal buckets or team buckets.
      </p>
    </div>
    <div class="container-custom mx-auto">
      <div class="flex flex-wrap mt-12">
        <div class="sm:w-full md:w-1/2 px-4 mt-16">
          <Title title="Private Bucket" headClasses="pl-3">
            <template v-slot:icon>
              <triangleInShield class="mr-3"/>
            </template>
          </Title>

          <BucketsLoading v-if="loading" />

          <BucketsLines 
            v-if="buckets.private && buckets.private.length"
            :items="buckets.private"
            :isCreate="true"
            @createNewBucket="createNewPrivateBucket"
          />

        </div>
        <div class="sm:w-full md:w-1/2 px-4 mt-16">
          <Title title="Team Buckets" headClasses="pl-3">
            <template v-slot:icon>
              <circlesOnCircle class="mr-3"/>
            </template>
          </Title>

          <BucketsLoading v-if="loading" />

          <BucketsLines 
            v-if="buckets.teams && buckets.teams.length"
            :items="buckets.teams"
            :isCreate="true"
            @createNewBucket="createNewTeamBucket"
          />
        </div>
      </div>
    </div>
    <div class="container-custom mx-auto mt-16 px-4">
      <Title 
        title="Community Buckets" 
        headClasses="pl-3"
        :search="true" 
        placeholder="Type here your searchterm"
        @search="filterPublicBuckets"
      >
        <template v-slot:icon>
          <pointInCircles class="mr-3"/>
        </template>
      </Title>
      <ul 
        v-if="buckets.public && buckets.public.length"
        class="flex flex-wrap w-full mt-2"
      >
        <li 
          v-for="(bucket) in buckets.public"
          :key="bucket.bucketId"
          class="sm:w-full md:w-1/2 lg:w-1/2 mt-8 flex flex-wrap"
        >
          <router-link 
            :to="`/buckets/${bucket.bucketId}`" 
            class="block w-full px-2"
          >
            <span class="w-full h-full border rounded bucket-line px-3 py-5 block bucket-block">
              <span class="text-xl text-black w-full block mb-1 leading-none"> {{ bucket.name }} </span>
              <span class="w-full block text-base base-font-gray-200 mb-2 border-b pb-3">{{JSON.parse(bucket.tags).length >= 1 ?  renderTag(JSON.parse(bucket.tags)) : ""}}{{ bucket.channels}} channels </span>
              <span class="font-sansDemi text-base w-full block"> {{ bucket.description }} </span>
            </span>
          </router-link>
        </li>
      </ul>

      <EmptyBuckets v-else :noResults="true"/>
    </div>
  </div>
</template>

<script>
import BucketsLines from '@/components/BucketsBlocks/BucketsLines'
import BucketsLoading from '@/components/BucketsBlocks/BucketsLoading.vue'
import EmptyBuckets from '@/components/BucketsBlocks/EmptyBuckets'
import Title from '@/components/Title'
import triangleInShield from '@/components/icons/triangle-in-shield'
import circlesOnCircle from '@/components/icons/circles-on-circle'
import pointInCircles from '@/components/icons/point-in-circles'

export default {
  name: 'BucketsOverview',
  components: { 
    BucketsLines, 
    BucketsLoading,
    EmptyBuckets, 
    Title,
    triangleInShield,
    circlesOnCircle,
    pointInCircles
  },
  data(){
    return{
      buckets: {},
      baseBuckets: {},
      loading: false
    }
  },
  methods: {
    createNewPrivateBucket(){
      this.$router.push({ path: '/bucket-admin', params: { bucketType: 'private' } })
    },
    createNewTeamBucket(){
      this.$router.push({ path: '/bucket-admin', params: { bucketType: 'team' } })
    },
    renderTag(arr) {
      let results = [];
      arr.map((tag) => results.push(tag.title))
      return `${results.join(" ")} | `;
    },
    filterPublicBuckets(event){
      if(event.length){
        this.buckets.public = this.baseBuckets.public.filter(elem => {
          return elem.bucket_name.toLowerCase().indexOf(event.toLowerCase()) != -1 
          || elem.bucket_summary.toLowerCase().indexOf(event.toLowerCase()) != -1
        })  
      }else{
        this.buckets.public = JSON.parse(JSON.stringify(this.baseBuckets.public))
      }
    }
  },
  async beforeMount(){
    try {
      this.loading = true
      const { data } = await this.$axios.get(`https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-bucket`);

      console.log('huh')
      console.log(data.readAccess.communityBuckets);

      this.buckets = {
        private: data.writeAccess.privateBuckets,
        teams: data.writeAccess.teamBuckets,
        public: data.readAccess.communityBuckets
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }    
  }
}
</script>

<style lang="scss" scoped>
.bucket-block{
  &:hover{
    background-color: #F9F9F9;
  }
}
</style>