<template>
  <ul>
    <li v-for="line in lines" :key="line" class="border-dashed border-b bucket-line">
      <div class="flex items-center justify-between py-3 px-3">
        <div class="animate-pulse rounded h-7 w-80 bg-gray-200"></div>
        <span class="animate-pulse rounded h-5 w-24 bg-gray-200"></span>
      </div>
    </li>
  </ul>

</template>
  
<script>
export default {
  name: 'BucketsLoading',
  data() {
    return {
      lines: [
        0,1,2,3,4,5
      ]
    }
  }

}
</script>
